import { FC } from "react";
import { gql, useMutation, useReactiveVar } from "@apollo/client";
import { currentPushTokenVar, setAuth } from "apollo/cache/auth";
import { useNavigate, useSearch } from "react-location";
import { useLocalStorage } from "react-use";
import { LocationGenerics } from "router/location";
import * as Yup from "yup";
import { TextInput } from "components/core";
import { useFormik } from "formik";
import { Link } from "react-location";

const LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(username: $username, password: $password) {
      user {
        _id
        code
        lastName
        firstName
        gender
        phoneNumber
        ghanaCardNumber
        emailAddress
        profileImageUrl
        role {
          _id
          code
          name
          applications {
            mis {
              code
              permissions
            }
          }
          permissions
        }
        region {
          _id
        }
        regionCode
        regionName
        district {
          _id
        }
        districtCode
        districtName
        meta {
          isPasswordSet
          lastLoginAt
        }
        createdAt
      }
      tokens {
        authorization
      }
    }
  }
`;

const LoginPage: FC = () => {
  const currentPushToken = useReactiveVar(currentPushTokenVar);

  const search = useSearch<LocationGenerics>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_forgotStore, setForgotStore] = useLocalStorage<{
    username?: string;
    tab: string;
    token?: string;
  }>("forgot-store", { username: "", tab: "send-code", token: "" });
  const navigate = useNavigate();
  const [login, { loading }] = useMutation(LOGIN);

  const { handleSubmit, ...form } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().email().required(),
      password: Yup.string().required(),
    }),
    onSubmit: async (values) => {
      await login({
        variables: {
          username: values.username.toLowerCase(),
          password: values.password,
          ...(currentPushToken ? { notificationToken: currentPushToken } : {}),
        },
      }).then(({ data }) => {
        if (data?.login) {
          if (!data.login?.user?.meta?.isPasswordSet) {
            setForgotStore({
              tab: "reset-password",
              token: data.login.tokens.authorization,
              username: data.login.user.firstName,
            });
            return navigate({
              to: "/forgot-password",
            });
          } else {
            setAuth({
              user: data?.login?.user,
              token: data.login.tokens?.authorization,
            });
            return navigate({
              replace: true,
              to: search?.redirect ?? "/",
            });
          }
        }
      });
    },
  });

  return (
    <div className="mx-auto w-full max-w-sm lg:w-96">
      <div>
        <div className="flex items-end flex-row">
          <div className="h-16 w-16 flex-shrink-0 p-1 bg-indigo-800">
            <img
              className="h-14 w-14 border-white border p-1"
              src={require("assets/logo.png")}
              alt="SUBS"
            />
          </div>
          <div className="ml-3">
            <span className="text-lg text-primary-600 font-mono">
              MIS Console
            </span>
          </div>
        </div>
        <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-sm text-gray-600">
          Enter your credentials below
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <TextInput
                id="username"
                label="Email address"
                type="email"
                placeholder="e.g. user@ecg.com"
                {...form}
              />
            </div>

            <div className="space-y-1">
              <TextInput
                id="password"
                label="Password"
                type="password"
                placeholder="e.g.  **************"
                {...form}
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  Remember me
                </label>
              </div>

              <div className="text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-primary-600 hover:text-primary-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                {loading ? "Signing in..." : "Sign in"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
